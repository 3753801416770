import React from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FormProvider, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Spinner } from '@chakra-ui/react';
import {
  Button,
  Form,
  Textarea,
  Input,
  Typography,
  Select,
} from '@/components/atoms';
import formValidationRules from '@/utils/formValidationRules';
import renderFormErrorMessage from '@/utils/renderFormErrorMessage';

import mq from '@/styles/mq';

interface ContactFormProps {
  lang: string;
}
interface OptionProps {
  [key: string]: {
    text: string;
  }[];
}
interface LabelsProps {
  [key: string]: {
    [key: string]: string;
  };
}
interface ButtonProps {
  [key: string]: string;
}
interface ErrorProps {
    [key: string]: string;
}

const FormContact = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const InputContainer = styled.div`
  width: 90%;
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  ${mq('sm')} {
    width: 70%;
  }
  ${mq('xl')} {
    margin-bottom: ${({ theme }) => theme.spacing(5)};
    width: 50%;
  }
`;
const SubmitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Submit = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
`;

const SubmitSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
`;

const Error = styled(Typography)`
  color: ${({ theme }) => theme.color.error.main};
`;

const encode = (data: any) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

const options: OptionProps = {
  'fr-be': [
    {
      text: 'Dépannage et réparation',
    },
    {
      text: 'Entretien de vos électroménagers',
    },
  ],
  'en-gb': [
    {
      text: 'Troubleshooting and repair',
    },
    {
      text: 'Maintenance of your appliances',
    },
  ],
  'nl-be': [
    {
      text: 'Problemen oplossen en repareren',
    },
    {
      text: 'Onderhoud van uw apparaten',
    },
  ],
};

const labels: LabelsProps = {
  'fr-be': {
    name: 'Nom',
    address: 'Adresse',
    phone: 'Téléphone',
    email: 'E-mail',
    message: 'Message',
  },
  'en-gb': {
    name: 'Name',
    address: 'Address',
    phone: 'Phone',
    email: 'E-mail',
    message: 'Message',
  },
  'nl-be': {
    name: 'Naam',
    address: 'Adres',
    phone: 'Telefoon',
    email: 'E-mail',
    message: 'Bericht',
  },
};

const button: ButtonProps = {
  'fr-be': 'Envoyer',
  'en-gb': 'Send',
  'nl-be': 'Sturen',
};

const errorMessage: ErrorProps = {
  'fr-be': 'Ce champ est requis',
  'en-gb': 'This field is required',
  'nl-be': 'Dit veld is verplicht',
};

const ContactForm = ({ lang }: ContactFormProps): JSX.Element => {
  const { color } = useTheme();
  const methods = useForm();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;
  const inputTextValidation = formValidationRules('text', true);
  const emailValidation = formValidationRules('email', true);
  const textareaValidation = formValidationRules('textarea', true);

  const selectOptions = options[lang];
  const labelsInput = labels[lang];
  const buttonLang = button[lang];
  const errorMessageLang = errorMessage[lang];

  const onSubmit = async (data: FormData) => {

    try {
      await fetch('/', {
        method: `POST`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: encode({
          ...data,
          'form-name': `contact-topservices`,
        }),
      });
      reset();
    } catch (e) {
      reset();
    }
  };
  return (
    //@ts-ignore
    <FormProvider {...methods}>
      <FormContact
        name={`contact-topservices`}
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
      >
        <InputContainer>
          <Input
            {...register('name', inputTextValidation)}
            name="name"
            label={labelsInput.name}
            star
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <Error variant="textXs">{errorMessageLang}</Error>
            )}
          />
        </InputContainer>
        <InputContainer>
          <Input {...register('address')} name="address" label={labelsInput.address} />
        </InputContainer>
        <InputContainer>
          <Input {...register('phone')} name="phone" label={labelsInput.phone} />
        </InputContainer>
        <InputContainer>
          <Input
            {...register('email', emailValidation)}
            name="email"
            label={labelsInput.email}
            star
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <Error variant="textXs">{errorMessageLang}</Error>
            )}
          />
        </InputContainer>
        <InputContainer>
          <Textarea
            {...register('message', textareaValidation)}
            name="message"
            label={labelsInput.message}
            star
          />
          <ErrorMessage
            errors={errors}
            name="message"
            render={({ message }) => (
              <Error variant="textXs">{errorMessageLang}</Error>
            )}
          />
        </InputContainer>
        <SubmitContainer>
          {isSubmitting ? (
            <div
              css={css`
                margin: 3rem 0 0 0;
              `}
            >
              <SubmitSpinner color={color.primary.main} />
            </div>
          ) : (
            <Submit variant="outlined" size="lg" type="submit">
              {buttonLang}
            </Submit>
          )}
        </SubmitContainer>
      </FormContact>
    </FormProvider>
  );
};

export default ContactForm;
