import React from 'react';
import { graphql } from 'gatsby';
import mq from '@/styles/mq';
import { SEO } from '@/components/atoms';
import { Layout } from '@/components/organisms';

import ContactContainer from '@/containers/Contact/ContactContainer';
import { PrismicContactPage } from '@/domain/PrismicContactPage';
import { PageContext } from '@/domain/PageContext';


interface ContactTemplateProps {
    data: {
        prismicContactP: PrismicContactPage;
    };
    pageContext: PageContext;
}

export const query = graphql`
  query ($id: String!) {
    prismicContactP(id: { eq: $id }) {
      id
      lang
      alternate_languages {
        lang
        uid
      }
      data {
        meta_title
        meta_description
        contact_content {
          raw
        }
      }
    }
  }
`;

const ContactTemplate = ({ data, pageContext }: ContactTemplateProps): JSX.Element => {
    const { alternate } = pageContext;
    const { lang, uid, alternate_languages } = data?.prismicContactP;
    const { meta_title, meta_description } = data?.prismicContactP?.data;

    const selectlang = {
        lang,
        uid: uid,
        alternate_languages,
    };
    const seo = {
        title: meta_title,
        description: meta_description,
        lang: lang,
        url: `${lang}`
    }
    const linkAlternate = alternate?.map(data => ({
        lang: data?.lang,
        url: `${data?.lang}/${data?.uid}`,
    }))

    return (
        <Layout selectLang={selectlang}>
            <SEO lang={seo.lang} title={`${seo.title}`} url={seo.url} description={seo.description} alternate={linkAlternate} />
            <ContactContainer data={data} lang={lang} />
        </Layout>
    );
};

export default ContactTemplate;
