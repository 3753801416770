import React from 'react';
import styled from '@emotion/styled';

import { Banner, Content, ContactBox } from '@/components/molecules';
import ContactForm from '@/containers/Contact/ContactForm';
import ContactMapBox from '@/containers/Contact/ContactMapBox';
import { PrismicContactPage } from '@/domain/PrismicContactPage';

interface ContactContainerProps {
    data: {
        prismicContactP: PrismicContactPage;
    };
    lang: string;
}

const ContactContainerRoot = styled.section``;

const ContactContainer = ({ data, lang }: ContactContainerProps): JSX.Element => {
    const { contact_content } = data?.prismicContactP?.data;

    const ContentData = {
        content: contact_content?.raw
    }

    return (
        <ContactContainerRoot>
            <Banner lang={lang} />
            <Content {...ContentData} />
            <ContactForm lang={lang} />
            {/* <ContactMapBox /> */}
            <ContactBox lang={lang} />
        </ContactContainerRoot>
    );
};

export default ContactContainer;